// extracted by mini-css-extract-plugin
export var project = "project-module--project--vZyNU";
export var project__backdrop = "project-module--project__backdrop--us955";
export var project__close = "project-module--project__close--hs2nt";
export var project__content = "project-module--project__content--yJNZl";
export var project__description = "project-module--project__description--lc2pD";
export var project__gallery = "project-module--project__gallery--NokVz";
export var project__gallery__image = "project-module--project__gallery__image--+8Q8y";
export var project__info = "project-module--project__info--kGeoT";
export var project__title = "project-module--project__title--uEGx0";
export var project__wrapper = "project-module--project__wrapper--jdvEk";
export var visible = "project-module--visible--YCNCw";