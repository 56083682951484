// extracted by mini-css-extract-plugin
export var circleMenu = "circle-menu-module--circle-menu--6PSA3";
export var circleMenu__contactIcon = "circle-menu-module--circle-menu__contactIcon--kCG3I";
export var circleMenu__contactItem = "circle-menu-module--circle-menu__contactItem--XAUx5";
export var circleMenu__contactLink = "circle-menu-module--circle-menu__contactLink--EOJFu";
export var circleMenu__content = "circle-menu-module--circle-menu__content--RIUGZ";
export var circleMenu__item = "circle-menu-module--circle-menu__item--fIb-a";
export var circleMenu__itemActive = "circle-menu-module--circle-menu__item--active--CiPrD";
export var circleMenu__itemBlue = "circle-menu-module--circle-menu__item--blue--GcDDR";
export var circleMenu__itemGreen = "circle-menu-module--circle-menu__item--green--i-z58";
export var circleMenu__itemOrange = "circle-menu-module--circle-menu__item--orange--rw+5c";
export var circleMenu__itemYellow = "circle-menu-module--circle-menu__item--yellow--2RVJ2";
export var circleMenu__link = "circle-menu-module--circle-menu__link--+w-bt";
export var circleMenu__list = "circle-menu-module--circle-menu__list--jcuo+";
export var circleMenu__list__showContent = "circle-menu-module--circle-menu__list__showContent--Ul7wZ";
export var circleMenu__toggle = "circle-menu-module--circle-menu__toggle--kzg0N";